import $ from 'jquery';

$(document).ready(() => {
    $('.upload__file').change(function() {
        mail.fileAdd(this);
    });
    $('.chat-input').submit(function(event) {
        event.preventDefault();
        mail.sendMessage();
    });

    if ($('.chat-input').length > 0) {
        mail.getMessages();

        let timer = setInterval(function() {
            if ($('.chat-window-flex').scrollTop() === 0) {
                mail.getMessages(mail.earlyMessage);
            }

            mail.getMessages();
        }, 2000);
    }
});

let mail = {
    chat: [],
    uploaded: false,
    sendMessage: function() {
        let adress = '/api/chat/messages',
            groupId = $('.chat-wrap').data('id'),
            data = new FormData(),
            file = $('#file-img'),
            input = $('.chat-input__message'),
            content = $(input).val(),
            testForEmpty = content.split(' ').join('');

        if (file[0].files.length > 0) {
            $.each(file[0].files, (i, file) => {
                data.append('images[]', file);
            });
        }

        if (testForEmpty.length === 0 && file[0].files.length === 0) {
            return;
        }

        if (content.length === 0 && file[0].files.length === 0) {
            return;
        }

        data.append('group_id', groupId);
        data.append('content', content);

        $.ajax({
            type: 'POST',
            url: adress,
            enctype: 'multipart/form-data',
            contentType: false,
            processData: false,
            data: data,
            success: function(resp) {
                let message = document.createElement('div'),
                    data = resp.data;

                $('.chat-window-flex').prepend(message);
                $(message).addClass(
                    'chat-window-message chat-window-message--self',
                );

                if (resp.hasOwnProperty('images[]')) {
                    mail.addImg(resp.images, message);
                }

                $(message).text(resp.data.content);

                mail.chat.push(resp.data.id);

                $(input).val('');
                $(file).val('');
                $(input).focus();

                if (resp.data.images.length > 0) {
                    mail.addImg(resp.data.images, message);
                    mail.fileAdd(file);
                }
            },
            error: function(resp) {},
        });
    },
    getMessages: function(start) {
        let adress = '/api/chat/messages',
            groupId = $('.chat-wrap').data('id'),
            data = {};

        data.group_id = +groupId;
        data.start = start;

        $.ajax({
            type: 'GET',
            url: adress,
            data: data,
            success: function(response) {
                let resp = response.data,
                    newMessage = [];

                if (resp.length === 0) {
                    return false;
                }

                if (mail.earlyMessage > resp[resp.length - 1].id) {
                    mail.addOld(resp, 1, response);
                } else if (
                    mail.earlyMessage === undefined &&
                    mail.chat.length === 0
                ) {
                    mail.addOld(resp, 0, response);
                } else if (mail.chat.length > 0) {
                    mail.chat.sort((a, b) => a - b);

                    for (let i = 0; i < resp.length; i++) {
                        if (
                            resp[i].id > mail.chat[mail.chat.length - 1] ||
                            mail.chat.indexOf(resp[i].id) < 0
                        ) {
                            newMessage.unshift(resp[i]);
                        }
                    }

                    mail.addNew(newMessage, response);
                } else {
                    for (let i = 0; i < resp.length; i++) {
                        newMessage.push(resp[i]);
                    }

                    mail.addNew(newMessage, response);
                }
            },
            error: function(resp) {},
        });
    },
    addImg: function addImg(images, message) {
        let imgBox = document.createElement('div');

        $(message).append(imgBox);
        $(imgBox).addClass('chat-window-images');

        for (let i = 0; images.length > i; i++) {
            let img = document.createElement('img');

            $(imgBox).append(img);
            $(img).addClass('chat-window-images__item');
            $(img).attr('src', images[i].download_link);
        }
    },
    fileAdd: function(input) {
        if ($(input).val() !== '') {
            if (!this.uploaded) {
                $('.communicate-form-wrap__icon').toggleClass(
                    'communicate-form-wrap__icon--hidden',
                );
                $('.communicate-form-wrap__icon').toggleClass(
                    'communicate-form-wrap__icon--visible',
                );
                this.uploaded = true;
            }
        } else {
            $('.communicate-form-wrap__icon').toggleClass(
                'communicate-form-wrap__icon--hidden',
            );
            $('.communicate-form-wrap__icon').toggleClass(
                'communicate-form-wrap__icon--visible',
            );
            this.uploaded = false;
        }
    },
    addNew: function(newMessage, response) {
        for (let i = 0; newMessage.length > i; i++) {
            let message = document.createElement('div');

            $('.chat-window-flex').prepend(message);
            $(message).addClass('chat-window-message');
            $(message).text(newMessage[i].content);

            if (newMessage[i].author_id === response.self_id) {
                $(message).addClass('chat-window-message--self');
            } else {
                $(message).addClass('chat-window-message--from');
            }

            if (newMessage[i].hasOwnProperty('images')) {
                this.addImg(newMessage[i].images, message);
            }
        }

        newMessage.forEach(item => this.chat.push(item.id));
    },
    addOld: function(resp, i, response) {
        this.earlyMessage = resp[resp.length - 1].id;

        for (i; resp.length > i; i++) {
            let message = document.createElement('div');

            $('.chat-window-flex').append(message);
            $(message).addClass('chat-window-message');
            $(message).text(resp[i].content);

            if (resp[i].author_id === response.self_id) {
                $(message).addClass('chat-window-message--self');
            } else {
                $(message).addClass('chat-window-message--from');
            }

            if (resp[i].hasOwnProperty('images')) {
                this.addImg(resp[i].images, message);
            }
        }

        resp.forEach(item => this.chat.unshift(item.id));
    },
};
