import $ from 'jquery';

// Enable inline svg in IE 11
import svg4everybody from 'svg4everybody';
// Enable picture in IE 11
import pictureFill from 'picturefill';
// Enable object-fit in IE 11
import objectFitImages from 'object-fit-images';

// slick
import 'slick-carousel';

import IMask from 'imask';

// fancybox 3
import '@fancyapps/fancybox';

// swipe
import 'jquery-touchswipe';

// Pages
import './page/index.js';
import './page/technology.js';
import './page/about-history.js';
import './page/contacts-map.js';
import './page/category.js';
import './page/product-card.js';
import './page/favorites.js';
import './page/questions.js';
import './page/settings.js';
import './page/busket.js';
import './page/blog.js';
import './page/redactored.js';
import './page/support.js';
import './page/chat.js';
import './page/search.js';
import './page/order.js';

// Modules
import './modules/captcha-check.js';
import './modules/cabinet-aside.js';
import './modules/dataTable.js';
import './modules/pop-up.js';
import './modules/share.js';
import './modules/cookie.js';
import './modules/cityComfirmation.js';

svg4everybody();
pictureFill();
objectFitImages();

let element = $('.tel');

element.each((index, elem) => {
    let phoneMask = IMask(elem, {
        mask: '+{7}(000)000-00-00',
    });
});
