import $ from 'jquery';

$(document).ready(() => {
    // stages
    $('.technology-control').on('click', function() {
        let currentStage = '';

        $(this)
            .addClass('active')
            .siblings()
            .removeClass('active');

        currentStage = $(this).data('stage');
        $('.stage-content[data-stage-content=' + currentStage + ']')
            .addClass('active-stage')
            .siblings()
            .removeClass('active-stage');
    });

    // carousel (not on about page)
    if ($('.technology-slider').length !== 0) {
        $('.photo-slider').slick({
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        infinite: true,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        arrows: false,
                        variableWidth: true,
                    },
                },
            ],
        });
    }

    $('.arrow-left').click(function() {
        $(this)
            .closest('.photo-carousel')
            .find('.photo-slider')
            .slick('slickPrev');
    });

    $('.arrow-right').click(function() {
        $(this)
            .closest('.photo-carousel')
            .find('.photo-slider')
            .slick('slickNext');
    });

    $('.part-news-title-box--button__left').click(function() {
        $('.blog-index-wrapper').slick('slickPrev');
    });
    $('.part-news-title-box--button__right').click(function() {
        $('.blog-index-wrapper').slick('slickNext');
    });

    $('[data-fancybox="gallery"]').fancybox({
        buttons: ['close'],
        infobar: false,
        idleTime: false,
        loop: true,
        baseTpl:
            '<div class="fancybox-container" role="dialog" tabindex="-1">' +
            '<div class="fancybox-bg"></div>' +
            '<div class="fancybox-inner">' +
            '<div class="fancybox-stage"><div class="fancybox-toolbar">{{buttons}}</div><div class="fancybox-navigation">{{arrows}}</div></div>' +
            '<div class="fancybox-caption"></div>' +
            '</div>' +
            '</div>',
        btnTpl: {
            close:
                '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">' +
                '</button>',

            // Arrows
            arrowLeft:
                '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">' +
                '</button>',

            arrowRight:
                '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">' +
                '</button>',
        },
    });
});
