import $ from 'jquery';

let form = $('.communicate-form'),
    globalPage = 1,
    lastPage,
    idList = new Set();

$(document).ready(() => {
    $(form).submit(function(event) {
        event.preventDefault();
        sendFeedback();
    });
    $('.communicate-form__submit').click(function() {
        $(form).addClass('form_send');
    });
    $('.btn-support-item').click(function() {
        loadMoreSupport(globalPage);
    });

    if ($('.support-message').length > 0) {
        loadMoreSupport(1);
        support.ini();
    }
});

function sendFeedback() {
    $('.pop-up--support').toggleClass('pop-up--hidden');

    let file = $('.upload__file')[0].files,
        formData = new FormData();

    formData.append('_token', $('input[name=_token]').val());
    formData.append('name', $('input[name=name]').val());
    formData.append('company', $('input[name=company]').val());
    formData.append('phone', $('input[name=phone]').val());
    formData.append('email', $('input[name=email]').val());
    formData.append('city', $('input[name=city]').val());
    formData.append('message', $('textarea[name=message]').val());
    formData.append('file', file[0]);
    $.ajax({
        type: 'POST',
        url: '/ru/feedback',
        contentType: false,
        processData: false,
        data: formData,
    });

    $(form)[0].reset();
    $(form).removeClass('form_send');
    $('.communicate-form-wrap__icon').removeClass(
        'communicate-form-wrap__icon--hidden',
    );
    $('.i-file-input-icon_uploaded').addClass(
        'communicate-form-wrap__icon--hidden',
    );
    grecaptcha.reset(); /* eslint-disable-line */
}

function loadMoreSupport(page) {
    let adress = '/api/chat/supports',
        data = {};

    data.page = page;

    $.ajax({
        type: 'GET',
        url: adress,
        data: data,
        success: function(resp) {
            lastPage = resp.lastPage;

            if (lastPage > page) {
                $('.btn-support').removeClass('pop-up--hidden');
            }

            $(resp.data).each((index, item) => {
                if (!idList.has(item.id)) {
                    $('.btn-support').before(addAppeal(item));
                    idList.add(item.id);
                }
            });

            if (lastPage === globalPage) {
                $('.btn-support').remove();
            }

            globalPage += 1;
        },
    });
}

function addAppeal(resp) {
    return $('<a class="support-appeal-wrap"></a>')
        .attr('href', 'support/' + resp.id)
        .attr('data-support', resp.id)
        .append(
            $('<div class="support-appeal-item"></div>')
                .append(
                    $('<div class="support-appeal-item__name"></div>').text(
                        resp.subject,
                    ),
                )
                .append(
                    resp.has_messages
                        ? $(
                              '<div class="support-appeal-item__info"></div>',
                          ).text(
                              'Есть ответа администратора | ' + resp.created_at,
                          )
                        : '',
                ),
        );
}

let support = {
    $select: $('.support__select'),
    $text: $('.support__text'),
    $file: $('.support__file'),
    $form: $('.support-message'),
    $btn: $('.support-message-buttons__submit'),
    data: new FormData(),

    ini: function() {
        this.$form.submit(function(event) {
            event.preventDefault();
            support.saveSupport();
        });
        this.$btn.click(function() {
            support.checkForm();
        });
    },
    checkForm: function() {
        this.subject = this.$select.val();
        this.message = this.$text.val();

        if (this.message.length < 1) {
            this.$text.css('border-color', 'red');
            this.$text.keyup(function() {
                support.$text.css('border-color', '');
            });
        }
        if (this.subject === null) {
            this.$select.css('border-color', 'red');
            this.$select.change(function() {
                support.$select.css('border-color', '');
            });
        }
    },
    saveSupport: function() {
        this.checkForm();
        this.data.append('subject', this.subject);
        this.data.append('message', this.message);

        if (this.$file[0].files.length > 0) {
            $.each(this.$file[0].files, (i, file) => {
                this.data.append('images[]', file);
            });
        }

        $.ajax({
            type: 'POST',
            url: '/api/chat/start',
            enctype: 'multipart/form-data',
            contentType: false,
            processData: false,
            data: this.data,
            success: function(resp) {
                support.$form[0].reset();

                $('.pop-up--support').toggleClass('pop-up--hidden');
                $('.support-appeal__title').after(addAppeal(resp.data));

                if (resp.data.file !== null) {
                    $('.upload__file').change();
                }
            },
            error: function() {},
        });
    },
};
