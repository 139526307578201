import $ from 'jquery';

$(document).ready(() => {
    $('.settings-column-flex__disable').on('click', function() {
        redact();
    });
    $('.settings-column-flex__redact').on('click', function() {
        redact();
    });
    $('.settings-change_password').on('click', function() {
        showPasswordChange();
    });
    $('.settings-password__disable').on('click', function() {
        showPasswordChange();
    });
    $('.settings-subscribes-wrap__input').change(function() {
        addSubscribe(event.target);
    });
    $('.settings-column__submit').on('click', function() {
        $('.settings-redact').addClass('form_send');
    });
    $('.settings-base__submit').click(function(event) {
        event.preventDefault();
        saveBase();
    });
    $('.settings-password__submit').click(function(event) {
        event.preventDefault();
        savePass();
    });

    document.addEventListener(
        'invalid',
        (function() {
            return function(e) {
                e.preventDefault();

                let name = document.getElementById('name');

                if (name) {
                    name.focus();
                }
            };
        })(),
        true,
    );

    $('.settings-password').submit(function() {
        showPasswordChange();
    });
});

function redact() {
    $('.settings-redact').toggleClass('settings-hidden');
    $('.settings-info').toggleClass('settings-hidden');
    $('.settings-redact').removeClass('form_send');
}
function showPasswordChange() {
    $('.settings-password').toggleClass('settings-hidden');
    $('.settings-change_password').toggleClass('settings-hidden');
    $('.settings-password').removeClass('form_send');
    $(':password').val('');
    $(':password').css('border-color', '');
    $('.settings-password-errors').empty();
}
function addSubscribe(e) {
    let adress = '/api/user/notification',
        data = {};

    data[e.name] = e.checked;

    $.ajax({
        type: 'PUT',
        url: adress,
        data: data,
        success: function() {
            $(e)
                .parent()
                .toggleClass('settings-subscribe__checked');
        },
    });
}
function saveBase() {
    let adres = '/api/user/settings',
        inputs = $('.settings-base'),
        inputsVal = [],
        data = {};

    for (let i = 0; i < inputs.length; i++) {
        inputsVal.push($(inputs[i]).val());
    }

    data.company = {
        name: inputsVal[0],
        inn: inputsVal[1],
        phone: inputsVal[2],
        schedule: inputsVal[3],
    };
    data.name = inputsVal[4];
    data.position = inputsVal[5];
    data.phone = inputsVal[6];
    data.email = inputsVal[7];
    data.address = inputsVal[8];

    $.ajax({
        type: 'PUT',
        url: adres,
        data: data,
        success: function(resp) {
            changeRedactored(resp.data);
            redact();
            $('.header-user__username')
                .children('a')
                .text(inputsVal[4]);
        },
    });
}
function savePass() {
    let adres = '/api/user/password',
        inputs = $('.settings-password__input'),
        inputsVal = [],
        data = {};

    for (let i = 0; i < inputs.length; i++) {
        inputsVal.push($(inputs[i]));
    }

    data.password = inputsVal[2].val();
    data.password_confirmation = inputsVal[1].val();
    data.password_old = inputsVal[0].val();

    $.ajax({
        type: 'PUT',
        url: adres,
        data: data,
        success: function(resp) {
            $('.settings-password__input').css('border-color', '');
            showPasswordChange();
        },
        error: function(response) {
            let resp = response.responseJSON.error,
                errors = [],
                errorBox = $('.settings-password-errors');

            $(errorBox).empty();

            if (resp.hasOwnProperty('password')) {
                resp.password.forEach(error => {
                    errors.push(error);
                });
            }
            if (resp.hasOwnProperty('password_old')) {
                resp.password_old.forEach(error => {
                    errors.push(error);
                });
            }

            errors.forEach(error => {
                $(errorBox).append(addAlert(error));
            });

            inputs.each((index, item) => {
                $(item).css('border-color', 'red');
                $(item).keyup(() => {
                    $(item).css('border-color', '');
                });
            });
            $('.settings-password').addClass('form_send');
        },
    });
}
function changeRedactored(resp) {
    $('#company-company').text(resp.company.name);
    $('#company-inn').text(resp.company.inn);
    $('#company-phone').text(resp.company.phone);
    $('#company-time').text(resp.company.schedule);
    $('#person-name').text(resp.name);
    $('#person-role').text(resp.position);
    $('#person-phone').text(resp.phone);
    $('#person-mail').text(resp.email);
    $('#delivery-addres').text(resp.address);
}
function addAlert(error) {
    return $('<span class="settings-password__alert"></span>').text(error);
}
