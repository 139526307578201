import $ from 'jquery';
import { Paginator } from '../modules/pagination.js';

let timer;

$(document).ready(() => {
    if ($('.favorites-cards').length > 0) {
        let favourite = new FavouriteList();

        $('.favorites-category--item').on('click', function() {
            favourite.favoritesCategory(event.target);
            favourite.startPage = 1;
            favourite.chosenPage = 1;
        });
        $('.favorites-category--input').keyup(function(e) {
            favourite.searchInput = $(this).val();

            if (e.which === 13) {
                favourite.favoritesSearch(true);
            } else {
                favourite.favoritesSearch(false);
            }
        });

        if ($('.favorites-category').length > 0) {
            favourite.ini();
        }
    }
});

class FavouriteList extends Paginator {
    constructor() {
        super();
    }

    categoryId = null;
    searchInput = '';

    ini() {
        let that = this;

        this.chosenPage = this.startPage = 1;

        $.ajax({
            type: 'GET',
            url: '/api/favourites',
            data: {
                page: this.chosenPage,
                category_id: this.categoryId,
                search: this.searchInput,
            },
            success: function(response) {
                that.createProductList(response.data);
                $(that.list).empty();
                that.lastPage = response.lastPage;
                that.chosenPage = 1;

                if (response.lastPage > 1) {
                    that.createPaginationList();
                }
            },
        });
    }

    favoritesSearch(submited) {
        let that = this;

        clearTimeout(timer);

        if (submited) {
            this.ini();
        } else {
            timer = setTimeout(function() {
                that.ini();
            }, 2000);
        }
    }

    favoritesCategory(e) {
        $('.favorites-category--item').removeClass(
            'favorites-category--item_active',
        );
        $(e).addClass('favorites-category--item_active');

        this.categoryId = e.getAttribute('data-id');
        this.ini();
    }

    createList() {
        let that = this;

        $.ajax({
            type: 'GET',
            url: '/api/favourites',
            data: {
                page: this.chosenPage,
                category_id: this.categoryId,
                search: this.searchInput,
            },
            success: function(response) {
                that.lastPage = response.lastPage;

                that.createProductList(response.data);
            },
        });
    }
    createProductList(data) {
        super.createProductList.call(this, data, $('.favorites-cards'));
    }

    createPaginationList() {
        super.createPaginationList.call(this);
    }
}
