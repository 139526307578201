import $ from 'jquery';
import 'jplayer';

$(document).ready(() => {
    $('.video-player').each(function() {
        let currentPlayer = $(this),
            currentContainer = $(this).parent(),
            video = $(currentPlayer)
                .find('video')
                .attr('src');

        $(this).jPlayer({
            ready: function() {
                $(this).jPlayer('setMedia', {
                    m4v: video,
                });
            },
            supplied: 'webmv, ogv, m4v',
            autohide: {
                restored: true,
                hold: 2000,
            },
            cssSelector: {
                play: '.jp-play',
                videoPlay: '.jp-video-play',
                seekBar: '.jp-seek-bar',
                playBar: '.jp-play-bar',
                currentTime: '.jp-current-time',
                duration: '.jp-duration',
                gui: '.jp-gui',
                fullScreen: '.jp-full-screen',
            },
            useStateClassSkin: true,
        });

        let currentControlsId = $(this).attr('id') + '-container';

        currentContainer.attr('id', currentControlsId);
        $(this).jPlayer(
            'option',
            'cssSelectorAncestor',
            '#' + currentControlsId,
        );

        $(this)
            .parent()
            .find('.jp-video-play')
            .click(function() {
                currentPlayer
                    .parent()
                    .find('.jp-my-controls')
                    .addClass('active-controls');
            });
    });

    if ($('.post-content').length !== 0) {
        let children = $('.photo-carousel-list').children();

        for (let i = 0; i < children.length; i++) {
            $('.photo-carousel-list').append($(children[i]).clone());
        }

        $('.photo-slider').slick({
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        infinite: true,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        variableWidth: true,
                        arrows: false,
                    },
                },
            ],
        });
        $('.arrow-left').click(function() {
            $(this)
                .closest('.photo-carousel')
                .find('.photo-slider')
                .slick('slickPrev');
        });

        $('.arrow-right').click(function() {
            $(this)
                .closest('.photo-carousel')
                .find('.photo-slider')
                .slick('slickNext');
        });
    }
});
