import $ from 'jquery';
import PhotoSwipeMounter from 'jquery.photoswipe';
import 'jquery-zoom';

PhotoSwipeMounter(jQuery);

$(document).ready(() => {
    $('#unit-form-2').attr('disabled', false);

    if ($('.card ').length > 0) {
        productOrder.getData();

        productOrder.formatCounter();

        let links = [],
            months = [
                'Января',
                'Февраля',
                'Марта',
                'Апреля',
                'Мая',
                'Июня',
                'Июля',
                'Августа',
                'Сентября',
                'Ноября',
                'Декабря',
            ];

        if ($('.card-bottom').length > 0) {
            let aside = $('.card-tests-aside__item');

            $(aside).each((index, item) => {
                let imgBox = $(item).children('img')[0],
                    imgLink = $(item)
                        .attr('data-link')
                        .split('v=')[1],
                    monitor = $('.card-tests-play').children('iframe');

                links.push(imgLink);

                $(imgBox).attr(
                    'src',
                    '//img.youtube.com/vi/' + imgLink + '/mqdefault.jpg',
                );
                $(monitor).attr(
                    'src',
                    'https://www.youtube.com/embed/' + links[0] + '?rel=0',
                );
                $(item).click(function() {
                    $(monitor).attr(
                        'src',
                        'https://www.youtube.com/embed/' +
                            links[index] +
                            '?rel=0',
                    );
                });
                $.ajax({
                    type: 'get',
                    url:
                        'https://www.googleapis.com/youtube/v3/videos?id=' +
                        imgLink +
                        '&key=AIzaSyCqm5h1PjxZMkID1BwgnNjBJRby2Ia1wlU&part=snippet,statistics',
                    success: function(resp) {
                        $(item)
                            .find('.card-tests-desc__name')
                            .text(resp.items[0].snippet.title);

                        let ms = Date.parse(resp.items[0].snippet.publishedAt),
                            date = new Date(ms),
                            data =
                                date.getDay() +
                                ' ' +
                                months[date.getMonth()] +
                                ' ' +
                                date.getFullYear();

                        $(item)
                            .find('.card-tests-desc__date')
                            .text(data);
                    },
                    error: function(resp) {},
                });
            });
        }

        $('.js-card-shop').on('submit', function(e) {
            e.preventDefault();
            productOrder.formSend();
        });
        $('.card-shop-form__btn').on('click', function(e) {
            let chosenForm = $(e.target).text();

            $('.card-shop-chosen_form').text(chosenForm);
        });
        $('.card-news-title--button__left').on('click', function() {
            productCard.scrollList(0);
        });
        $('.card-news-title--button__right').on('click', function() {
            productCard.scrollList(1);
        });
        $('input[name="unit-form"]').on('click', function(e) {
            productOrder.unitSelect($(e.target).val());
        });
        $('.card-shop-count-plus').on('click', function() {
            productOrder.stepChangeValue(+1);
        });
        $('.card-shop-count-minus').on('click', function() {
            productOrder.stepChangeValue(-1);
        });
        $('.card-shop-count-value').on('keydown', function(e) {
            productOrder.currentVal = $(e.target).val();
        });
        $('.card-shop-count-value').on('input', function() {
            let input = $(event.target).val();

            if (isNaN(input)) {
                input.toString();
                input = input.slice(0, -1);
                $(event.target).val(input);
            }

            clearTimeout(productOrder.timer);
            productOrder.timer = setTimeout(function() {
                productOrder.inputChangeValue();

                if (productOrder.data.count > 0) {
                    productOrder.changeSend();
                }
            }, 1000);
        });

        $('.card-panel__btn--1').on('click', function() {
            productCard.openDesc(1);
        });
        $('.card-panel__btn--2').on('click', function() {
            productCard.openDesc(2);
        });
        $('.card-panel__btn--3').on('click', function() {
            productCard.openDesc(3);
        });
        $('.js-card-icon--favourite').on('click', function() {
            productCard.addFavorite();
        });

        let slider = $('.card-slider-wrap'),
            slidesCount = $(slider).children().length;

        if (slidesCount > 0) {
            $(slider).slick({
                dots: true,
                prevArrow: false,
                nextArrow: false,
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                arrows: false,
                autoplay: false,
                autoplaySpeed: 7500,
                pauseOnHover: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                customPaging: function(slider, i) {
                    let thumb = $(slider.$slides[i]).data();

                    return '<span></span>';
                },
            });
            $(slider).on('beforeChange', function(
                event,
                slick,
                currentSlide,
                nextSlide,
            ) {
                console.log($(slick.$slides[currentSlide]));

                let currentSlideVideo = $(slick.$slides[currentSlide])
                    .find('.video')
                    .get(0);

                if (currentSlideVideo) currentSlideVideo.pause();
            });

            $(slider).on('afterChange', function(event, slick, currentSlide) {
                let currentSlideVideo = $(slick.$slides[currentSlide])
                    .find('.video')
                    .get(0);

                if (currentSlideVideo) currentSlideVideo.play();
            });
        }

        if ($('.product').length > 0) {
            initPhotoSwipe();
            $('.card-slider-wrap .one-slide .button-zoom').show();
        } else {
            if (slidesCount > 0) {
                $('.card-slider-wrap .one-slide').click(function() {
                    $(slider).slick('slickNext');
                });
            }
        }
    }

    if ($('.button-order').length > 0) {
        $('.button-order').click(() => {
            $('#popup-order.pop-up__map-city').toggleClass('pop-up--hidden');
        });
        $('#popup-order .map-city-input').keyup(function(event) {
            citySearch($(event.target).val());
        });
    }

    $('#popup-order .map-city-column__item').click(function() {
        let cityId = $(this).attr('data-id');

        $.ajax({
            type: 'GET',
            url: '/api/partners/' + cityId,
            success: function(response) {
                let partners = $.parseJSON(response);

                $('#popup-order .map-city-list')
                    .hide()
                    .before(
                        '<div class="partners"><a href="#" class="back">Назад к списку</a></div>',
                    );

                if (partners.length > 0) {
                    $.each(partners, function(index, partner) {
                        let data = {
                                address: partner.address,
                                email: partner.email,
                                phone: partner.phone,
                                schedule: partner.schedule,
                            },
                            html = renderTemplate(
                                'partner-info-template',
                                data,
                            );

                        $('#popup-order .pop-up-wrap .partners').append(html);
                    });
                }
            },
        });
    });

    $(document).on(
        'click',
        '#popup-order .pop-up-wrap .partners .back',
        function(e) {
            e.preventDefault();

            $('#popup-order .map-city-list').show();
            $('#popup-order .pop-up-wrap .partners').remove();
            $('#popup-order .pop-up-wrap .map-city-input').val('');
            citySearch('');
        },
    );
});

function citySearch(key) {
    if (key.length > 0) {
        $('#popup-order .map-city-list').show();
        $('#popup-order .pop-up-wrap .partners').remove();
        $('#popup-order .map-city-column').addClass('map-city--hidden');
        $('#popup-order .map-city-search').removeClass('map-city--hidden');

        let target = $('#popup-order .map-city-search').children();

        $(target).each((index, item) => {
            let align = true;

            for (let j = 0; j < key.length; j++) {
                align *=
                    $(item)
                        .text()
                        .toLowerCase()[j] === key[j].toLowerCase();
            }
            if (align) {
                $(item).removeClass('map-city--hidden');
            } else {
                $(item).addClass('map-city--hidden');
            }
        });
    } else {
        $('#popup-order .map-city-search').addClass('map-city--hidden');

        $('#popup-order .map-city-column').removeClass('map-city--hidden');
    }
}

function renderTemplate(name, data) {
    let template = document.getElementById(name).innerHTML;

    for (let property in data) {
        if (data.hasOwnProperty(property)) {
            let search = new RegExp('{' + property + '}', 'g');

            template = template.replace(search, data[property]);
        }
    }

    return template;
}

function initPhotoSwipe() {
    /* PhotoSwipe */

    let slideSelector = '.one-slide .button-zoom',
        options = {
            bgOpacity: 0.8,
        },
        events = {
            close: function() {
                // console.log('closed');
            },
        };

    $('.product .card-slider-wrap').photoSwipe(slideSelector, options, events);

    $('.product .card-slider-wrap .one-slide .image').zoom();
}

let productCard = {
        scrollList: function(e) {
            let originShift = $('.card-others').css('width'),
                shift =
                    $('.card-others-wrap').offset().left -
                    $('.card-others').offset().left,
                chain = parseInt($('.card-others-item').css('width')) + 24;

            originShift = parseInt(originShift, 10) + 24;

            if (e) {
                shift = parseInt((-shift + originShift) / chain) * chain;
            } else {
                shift =
                    -parseInt(originShift / chain) * chain -
                    parseInt(shift / chain) * chain;
            }

            $('.card-others').animate({ scrollLeft: shift }, 500);
        },
        openDesc: function(numb) {
            $('.card-panel__btn').removeClass('card-panel__btn--on');
            $('.card-variable').addClass('card-hidden');
            switch (numb) {
                case 1: {
                    $('.card-description').removeClass('card-hidden');
                    $('.card-panel__btn--1').addClass('card-panel__btn--on');
                    break;
                }
                case 2: {
                    $('.card-tests').removeClass('card-hidden');
                    $('.card-panel__btn--2').addClass('card-panel__btn--on');
                    break;
                }
                case 3: {
                    $('.card-documents').removeClass('card-hidden');
                    $('.card-panel__btn--3').addClass('card-panel__btn--on');
                    break;
                }
            }
        },
        addFavorite() {
            let addToFav =
                '/api/favourites?product_id=' +
                window.location.pathname.split('/')[3];

            $.ajax({
                type: 'post',
                url: addToFav,

                success: function() {
                    let $headerIcon = $('.js-header-icon--favourite'),
                        $cardIcon = $('.js-card-icon--favourite'),
                        hIconActive = 'header-icon--active',
                        cIconActive = 'card-icon--favourite_active';

                    if ($headerIcon.hasClass(hIconActive)) {
                        $headerIcon.removeClass(hIconActive);
                        $cardIcon.removeClass(cIconActive);
                    } else {
                        $headerIcon.toggleClass('header-icon--favourite_taped');
                        $cardIcon.toggleClass('card-icon--favourite_taped');
                    }
                },
            });
        },
    },
    productOrder = {
        timer: null,
        blocks: 1,
        getData: function() {
            this.counterBox = $('.card-shop-count-value');
            this.defaultVal = +$(this.counterBox).val();
            this.increment = +$(this.counterBox).attr('data-increment');
            this.baseUnit = $('#unit-form-1').val();
            this.data = {
                productId: $('input[name="product_id"]').val(),
                unit: $('input[name="unit-form"]:checked').val(),
                count: productOrder.defaultVal,
            };
            $(this.counterBox).val(this.defaultVal);
            $(this.counterBox).attr('value', this.defaultVal);
        },
        formatCounter: function() {
            let val = this.counterBox.val();

            val = this.round(val);
            this.counterBox.val(val);
        },
        unitSelect: function(unit) {
            this.data.unit = unit;

            if ($('.product').length > 0) {
                this.resetCounter = true;
            } else if ($('.card-theme--polymer').length > 0) {
                this.reformCount = true;
            }

            $.ajax({
                url: '/api/products/increment',
                data: this.data,
                type: 'GET',
                success: resp => {
                    this.increment = resp.increment;
                    this.defaultVal = resp.default;

                    if (this.resetCounter) {
                        this.data.count = this.blocks =
                            this.defaultVal > this.increment
                                ? this.defaultVal
                                : this.increment;

                        this.data.count = this.data.count.toFixed(2);
                        this.resetCounter = false;
                        this.counterBox.val(this.data.count);
                    }

                    if (this.reformCount) {
                        this.reformCount = false;
                        this.increment = this.defaultVal = resp.increment;
                        this.data.count = resp.increment * this.blocks;
                        this.data.count = this.data.count.toFixed(2);
                        this.counterBox.val(this.data.count);

                        return;
                    }

                    $('.card-shop-count__title--value').text(
                        '( ' + this.blocks + ' ' + this.baseUnit + ' )',
                    );
                },
            });
        },
        stepChangeValue: function(e) {
            let value = +this.data.count;

            value = +value + e * this.increment;

            if (value >= this.defaultVal) {
                this.data.count = value.toFixed(7);
            } else {
                this.data.count = this.defaultVal;
            }

            $(this.counterBox).val(this.round(this.data.count));
            this.changeSend();
        },
        inputChangeValue: function() {
            let value = +this.counterBox.val(),
                that = this;

            this.data.count = null;
            value = parseFloat(value);

            if (value === 0) {
                this.data.count = that.defaultVal;
            } else if (isNaN(value)) {
                this.data.count = that.defaultVal;
            } else {
                if (value < this.defaultVal) {
                    value = this.defaultVal;
                } else {
                    let check = value % this.increment;

                    value =
                        check !== 0
                            ? Math.round(value / this.increment) *
                              this.increment
                            : value;
                }

                this.data.count = value.toFixed(7);
            }

            $(this.counterBox).val(this.round(this.data.count));
        },
        round: function(input) {
            let output = null;

            if (input > 0.01) {
                output = input * 100;
                output = Math.round(output) / 100;
                output = output.toFixed(2);
            } else if (input > 0) {
                output = 0.01;
            }

            return output;
        },
        changeSend: async function() {
            $.ajax({
                url: '/api/products/calc-options',
                data: this.data,
                type: 'GET',
                success: response => {
                    let resp = response.options,
                        blocks = resp.blockTotal;

                    this.blocks = this.round(blocks.count);
                    this.increment = resp.increment;

                    $('.card-shop-count__title--value').text(
                        '( ' + this.blocks + ' ' + blocks.unit + ' )',
                    );
                },
                error: () => {},
            });
        },
        formSend: function() {
            this.data.product_id = this.data.productId;
            $.ajax({
                url: '/api/baskets/products',
                data: this.data,
                type: 'POST',
                success: response => {
                    $('.js-header-icon--basket').addClass(
                        'header-icon--basket_taped',
                    );

                    if (response.isNew) {
                        let $basketCounter = $('.basket-count'),
                            basketInfo = $basketCounter.text().split(' ');

                        basketInfo[0] = parseInt(basketInfo[0]) + 1;

                        basketInfo = basketInfo[0] + ' ' + basketInfo[1];

                        $basketCounter.text(basketInfo);
                    }
                },
            });
        },
    };

export { productOrder };
