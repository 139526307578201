import $ from 'jquery';

$(document).ready(() => {
    if ($('.order-about').length > 0) {
        $('.order-manager__question').click(function() {
            $('html,body').animate(
                { scrollTop: $('.chat').offset().top },
                'slow',
            );
            $('.chat-input__message').focus();
        });
        $('.js-order-slider').click(e => {
            let data = {},
                status = $(e.target).text();

            data.status = $(e.target).attr('data-status');
            data.orderGroupId = $(e.target)
                .parents('.order-status-slider')
                .attr('data-groupId');
            $('.js-order-status').click();
            $.ajax({
                type: 'PATCH',
                data: data,
                url: '/api/orders/status',
                success: function() {
                    $('.order-status-slider__item').removeClass(
                        'order-status--selected',
                    );
                    $(e.target).addClass('order-status--selected');
                    $('.js-status-title').text(status);
                },
                error: function() {},
            });
        });
        $('.js-order-status').click(function() {
            let slider = $('.order-status-slider'),
                filterArrow = $('.category-filter__icon'),
                wrap = $('.order-status-front');

            $(wrap).toggleClass('order-status-front--open');
            $(slider).toggleClass('category-filter-slider--open');
            $(filterArrow).toggleClass('category-filter__icon--open');
        });
        $('.js-order-repeat').click(function(event) {
            let id = $(event.target)
                    .parents('.order-result')
                    .attr('data-group'),
                data = { group_id: id },
                popUp = $('.pop-up--order_repeat');

            $.ajax({
                type: 'POST',
                data: data,
                url: '/api/orders/repeat',
                success: function() {
                    $(popUp).removeClass('pop-up--hidden');
                    $('.pop-up__next').click(function() {
                        $(popUp).addClass('pop-up--hidden');
                    });
                },
            });
        });
    }
});
