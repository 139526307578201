import $ from 'jquery';

// TODO fix all of this shit

$(document).ready(() => {
    $('.pop-up-check__item').click(function(event) {
        $(event.target)
            .parent()
            .toggleClass('settings-subscribe__checked');
    });
    $('.header-signup-btn').click(function() {
        $('.pop-up--registration').toggleClass('pop-up--hidden');
    });
    $('.open-pop-up__reset').click(function(event) {
        popUp.closeModal(event.target);
        $('.pop-up--reset').toggleClass('pop-up--hidden');
    });
    $('.pop-up--confirm-btn').click(function(event) {
        popUp.closeModal(event.target);
        $('.pop-up--authorization').toggleClass('pop-up--hidden');
    });
    /*    $('.header-login-btn').click(function() {
        $('.pop-up--authorization').toggleClass('pop-up--hidden');
    }); */
    $('.pop-up--login-btn').click(function(event) {
        popUp.closeModal(event.target);
        popUp.inputLightOff();
        $('.pop-up--authorization').toggleClass('pop-up--hidden');
    });
    $('.pop-up--signup-btn').click(function(event) {
        popUp.closeModal(event.target);
        popUp.inputLightOff();
        $('.pop-up--registration').toggleClass('pop-up--hidden');
    });
    $('.pop-up__map-city').click(function() {});
    $('.pop-up__background').click(function(event) {
        popUp.closeModal(event.target);
        popUp.inputLightOff();
        $('body').toggleClass('pop-up--overflow');
    });
    $('.pop-up--close').click(function(event) {
        popUp.closeModal(event.target);
        popUp.inputLightOff();
        $('body').toggleClass('pop-up--overflow');
    });
    $('select[name=registration-town]').change(function() {
        $(this).css('border-color', 'green');
    });

    $('.authorization-form--submit').click(function() {
        popUp.inputLightOn();
        popUp.autorization();
    });

    $('.registration-form--submit').click(function() {
        popUp.inputLightOn();
        popUp.registration();
    });
    $('.reset-form--submit').click(function() {
        popUp.inputLightOn();
        popUp.reset();
    });
    $('.confirm-form--submit').click(function() {
        popUp.inputLightOn();
        popUp.confirm();
    });

    popUp.ini();
});

let popUp = {
    ini: function() {
        this.regForm = $('.registration-form');
        this.authForm = $('.authorization-form');
        this.confForm = $('.confirm-form');
        this.resForm = $('.reset-form');
        this.regInputs = this.regForm.children('.pop-up__input');
        this.authInputs = this.authForm.children('.pop-up__input');
        this.confInputs = this.confForm.children('.pop-up__input');
        this.resInputs = this.resForm.children('.pop-up__input');

        $('input').focus(function(event) {
            let $target = $(event.target);

            $(this).css('border-color', '');

            $target.next('.pop-up__error').remove();
            $target.next('.pop-up__error').remove();
        });
    },

    autorization: function() {
        let data = $(this.authForm).serialize(),
            that = this;

        data = data.split('&');
        data = data.splice(0, 2);
        data = data.join('&');

        let formUrl = '/api/login?' + data;

        $.ajax({
            type: 'post',
            url: formUrl,
            success: function(response) {
                window.location.href = response.redirect_to;
            },
            error: function(response) {
                let resp = response.responseJSON.error;

                if (response.responseJSON.is_inactive) {
                    $(that.authForm)
                        .closest('.pop-up')
                        .toggleClass('non-confirmed');

                    that.closeModal = function(target) {
                        $(that.authForm)[0].reset();
                        $(target)
                            .closest('.pop-up')
                            .toggleClass('pop-up--hidden');
                        $(target)
                            .closest('.pop-up')
                            .removeClass('non-confirmed');
                    };
                    $('.pop-up__next').click(function(event) {
                        that.closeModal(event.target);
                    });
                    that.inputLightOff();
                } else {
                    that.inputLightOff();

                    if (resp.hasOwnProperty('email')) {
                        $(that.authInputs[0]).css('border-color', 'red');
                        $(that.authInputs[1]).css('border-color', 'red');
                        resp.email.forEach(error => {
                            $(that.authInputs[1]).after(that.addAlert(error));
                        });
                    }
                    if (resp.hasOwnProperty('password')) {
                        $(that.authInputs[0]).css('border-color', 'red');
                        $(that.authInputs[1]).css('border-color', 'red');
                        resp.password.forEach(error => {
                            $(that.authInputs[1]).after(that.addAlert(error));
                        });
                    }
                }
            },
        });
    },
    registration: function() {
        let data = $(this.regForm).serialize(),
            that = this;

        data = data.split('&');
        data = data.splice(1, 1000);
        data = data.join('&');

        let formUrl = '/api/register?' + data;

        $.ajax({
            type: 'post',
            url: formUrl,
            success: function(response) {
                $('.pop-up-wrap').removeClass('pop-up--hidden');
                $('.registration-form').addClass('pop-up--hidden');
                $('.pop-up__next').click(function() {
                    window.location.href = response.redirect_to;
                });
            },
            error: function(response) {
                let resp = response.responseJSON.error;

                if (resp.hasOwnProperty('email')) {
                    $(that.regInputs[0]).css('border-color', 'red');
                    resp.email.forEach(error => {
                        $(that.regInputs[0]).after(that.addAlert(error));
                    });
                }
                if (resp.hasOwnProperty('password')) {
                    $(that.regInputs[1]).css('border-color', 'red');
                    $(that.regInputs[2]).css('border-color', 'red');
                    resp.password.forEach(error => {
                        $(that.regInputs[2]).after(that.addAlert(error));
                    });
                }
            },
        });
    },
    confirm: function() {
        let that = this,
            $parent = $(this.confForm).parent('.pop-up--confirm'),
            $wrappers = $parent.find('.pop-up-wrap'),
            data = {
                password: $parent.find('.js-pop-up__input--first').val(),
                password_confirmation: $parent
                    .find('.js-pop-up__input--confirm')
                    .val(),
            };

        $.ajax({
            type: 'post',
            url: '/api/reset-password/' + $(this.confForm).attr('data-token'),
            data: data,
            success: function() {
                $wrappers.toggleClass('pop-up--hidden');
                that.inputLightOff();
            },
            error: function(response) {
                let resp = response.responseJSON.error;

                if (resp.hasOwnProperty('password')) {
                    resp.password.forEach(error => {
                        $(that.confInputs[1]).after(that.addAlert(error));
                    });
                    $(that.confInputs[0]).css('border-color', 'red');
                    $(that.confInputs[1]).css('border-color', 'red');
                }
                if (resp.hasOwnProperty('token')) {
                    $(that.confInputs[1]).after(that.addAlert(resp.token));
                }
            },
        });
    },
    reset: function() {
        let mail = $(this.resForm)
                .children('input[type=email]')
                .val(),
            formUrl = '/api/reset-password?email=' + mail,
            that = this,
            partlength = parseInt(mail.length / 3, 10),
            start = parseInt(mail.indexOf('@') / 2, 10);

        mail = mail.split('');

        for (let i = start; i < start + partlength; i++) {
            mail[i] = '*';
        }

        mail = mail.join('');

        $.ajax({
            type: 'post',
            url: formUrl,
            success: function(response) {
                $(that.resForm).toggleClass('pop-up--hidden');
                $(that.resForm)
                    .siblings('.pop-up-wrap')
                    .toggleClass('pop-up--hidden');
                $('.pop-up__text--mail').text(mail);
                that.inputLightOff();
            },
            error: function(response) {
                let resp = response.responseJSON.error;

                if (resp.hasOwnProperty('email')) {
                    resp.email.forEach(error => {
                        $(that.resInputs).after(that.addAlert(error));
                    });
                }

                $(that.resInputs).css('border-color', 'red');
            },
        });
    },
    inputLightOn: function() {
        $('.user-form').addClass('form_send');
        $('.pop-up__error').addClass('pop-up--hidden');
    },
    inputLightOff: function() {
        $('.user-form').removeClass('form_send');
        $('.pop-up__error').remove();
    },
    addAlert: function(resp) {
        return $('<div class="pop-up__error"></div>').text(resp);
    },
    closeModal: function(target) {
        $(target)
            .closest('.pop-up')
            .toggleClass('pop-up--hidden');

        $('.pop-up__error').remove();
        $('input').css('border-color', '');
        this.inputLightOff();
    },
};
