import $ from 'jquery';

class Paginator {
    constructor() {
        let params = window.location.search
            .replace('?', '')
            .split('&')
            .reduce(function(p, e) {
                let a = e.split('=');

                p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);

                return p;
            }, {});

        if (params['page'] !== typeof undefined && this.isInt(params['page'])) {
            this.chosenPage = parseInt(params['page']);
            this.startPage = parseInt(params['page']);
        } else {
            this.chosenPage = 1;
            this.startPage = 1;
        }

        // console.log(this.chosenPage);

        this.address = '';
        this.list = $('.pagination-wrap');
        this.lastPage = null;
        this.pages = [];
        this.openedPages = [];
        this.productGrid = {
            category_id: null,
            page: null,
            text: '',
        };
    }

    createPaginationList() {
        $(this.list).empty();
        this.openedPages = [];

        let startPage = this.startPage - 1,
            lastPage = this.lastPage - 1;

        // console.log(startPage);

        if (this.pages.length < 1) {
            for (let i = 1; i <= this.lastPage; i++) {
                this.pages.push(this.createPagBtn(i)[0]);
            }
        }

        if (this.lastPage < 4) {
            if (this.lastPage > 1) {
                for (let i = 0; i < this.lastPage; i++) {
                    $(this.list).append(this.pages[i]);
                }
            }
        } else if (this.startPage > 3 && this.startPage < this.lastPage - 3) {
            $(this.list).append(this.pages[0]);
            this.createMoveBtn('back');
            $(this.list).append(this.pages[startPage - 1]);
            $(this.list).append(this.pages[startPage]);
            $(this.list).append(this.pages[startPage + 1]);

            if (this.startPage + 2 === this.lastPage - 2) {
                $(this.list).append(this.pages[lastPage - 2]);
                $(this.list).append(this.pages[lastPage - 1]);
            } else {
                this.createMoveBtn('next');
            }

            $(this.list).append(this.pages[lastPage]);
            this.openedPages.push(this.startPage, this.startPage + 2);
        } else if (this.startPage === 3) {
            $(this.list).append(this.pages[0]);
            $(this.list).append(this.pages[1]);
            $(this.list).append(this.pages[2]);
            $(this.list).append(this.pages[3]);
            this.lastPage > 5
                ? this.createMoveBtn('next')
                : $(this.list).append(this.pages[4]);
            $(this.list).append(this.pages[lastPage]);
            // this.openedPages.push(1, 3);
        } else if (this.startPage < 3) {
            $(this.list).append(this.pages[0]);
            $(this.list).append(this.pages[1]);
            $(this.list).append(this.pages[2]);
            this.lastPage > 4
                ? this.createMoveBtn('next')
                : $(this.list).append(this.pages[3]);
            $(this.list).append(this.pages[lastPage]);
            this.openedPages.push(1, 3);
        } else {
            $(this.list).append(this.pages[0]);

            if (this.lastPage >= 6) {
                this.createMoveBtn('back');
            }
            // console.log(this.lastPage);
            // console.log((this.lastPage) % 3);
            /* if ((this.lastPage - 1) % 3 === 0) {
                $(this.list).append(this.pages[lastPage - 3]);
                this.openedPages.push(this.lastPage - 3);

            } else {
                this.openedPages.push(this.lastPage - 3);
            } */

            if (this.lastPage - this.startPage <= 4) {
                $(this.list).append(this.pages[lastPage - 4]);
                $(this.list).append(this.pages[lastPage - 3]);
                this.openedPages.push(this.lastPage - 4);
            } else {
                this.openedPages.push(this.lastPage - 2);
            }

            $(this.list).append(this.pages[lastPage - 2]);
            $(this.list).append(this.pages[lastPage - 1]);
            $(this.list).append(this.pages[lastPage]);
            this.openedPages.push(this.lastPage);
        }

        $('.js-btn--page').removeClass('btn--active');
        $('#pag-btn-' + this.chosenPage).addClass('btn--active');

        if (this.chosenPage < this.openedPages[0] && this.chosenPage !== 1) {
            $('.js-btn--move-back').addClass('btn--active');
        }
        if (
            this.openedPages[1] < this.chosenPage &&
            this.chosenPage !== this.lastPage
        ) {
            $('.js-btn--move-next').addClass('btn--active');
        }

        let that = this;

        /* $('.js-btn--page').on('click', function(event) {
            that.productGrid.page = that.chosenPage = that.startPage = +$(
                event.target,
            ).text();

            that.productGrid.category_id = +$('.category-products').attr(
                'data-category',
            );
            that.createList(that.productGrid);

            that.createPaginationList();
        }); */
        $('.js-btn--move').on('click', function(event) {
            let direction = $(event.target).attr('data-direction');

            direction = direction === 'next' ? 1 : -1;

            if (that.lastPage - that.startPage < 2) {
                direction = -2;
            }

            /* that.startPage =
                (Math.floor(that.startPage / 3) + direction) * 3 + 1; */

            that.startPage = that.startPage + 3 * direction;

            // console.log(that.startPage);

            that.createPaginationList();
        });

        /* if(this.stop){
            return false;
        }

        this.stop = true;
        that.createPaginationList(); */
    }

    createMoveBtn(direction) {
        let btn = document.createElement('div');

        $(btn).text('...');
        $(btn).addClass('btn');
        $(btn).addClass('js-btn--move');
        $(btn).addClass('js-btn--move-' + direction);
        $(btn).attr('data-direction', direction);
        $(this.list).append(btn);
    }

    createPagBtn(i) {
        let btn = document.createElement('a'),
            url = document.location.pathname;

        if (i > 1) {
            url = '?page=' + i;
        }

        $(btn).attr('href', url);
        $(btn).text(i);
        $(btn).addClass('btn');
        $(btn).addClass('js-btn--page');
        $(btn).attr('id', 'pag-btn-' + i);

        return $(btn);
    }

    createProductList(data, parent, removeOldData = true) {
        if (removeOldData) {
            $(parent).empty();
            // $('html, body').animate({ scrollTop: 250 }, 'slow');
        }

        data.forEach(val => {
            let link = document.createElement('a'),
                block = document.createElement('span'),
                discount = document.createElement('div'),
                linkAddress = '';

            /* $('.subcategory-list').length > 0
                ? (linkAddress = '')
                : (linkAddress = '../product/'); */

            $(link).attr('href', val.url);
            $(link).addClass('category-products-item');
            $(link).css('background-image', 'url(' + val.image + ')');
            $(block).addClass('category-products-item__text');
            $(block).text(val.name);
            $(parent).append(link);
            $(link).append(block);

            if (val.discount !== undefined && val.discount !== null) {
                $(discount).text(val.discount);
                $(discount).addClass('category-products-item__discount');
                $(link).append(discount);
            }
        });
    }

    isInt(value) {
        return (
            !isNaN(value) &&
            parseInt(Number(value)) == value &&
            !isNaN(parseInt(value, 10))
        );
    }
}

export { Paginator };
