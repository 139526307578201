import $ from 'jquery';
import { productOrder } from './product-card.js';

let basketClean = false;

$(document).ready(() => {
    if ($('.busket').length > 0) {
        $('.js-busket-aside').click(function(e) {
            if (window.matchMedia('(max-width: 1100px)').matches) {
                openBusketDesc(e.target);
            }
        });

        let articlePPU = $('.busket-category--polymer'),
            articleACS = $('.busket-category--accessories'),
            articleGLE = $('.busket-category--glow'),
            articleCUT = $('.js-textile--cuts'),
            articleRL = $('.js-textile--rolls'),
            articleSML = $('.js-textile--samples');

        if ($(articlePPU).length > 0) {
            let polymer = new PPU(articlePPU);

            polymer.ini();
            polymer.massRecount();
        }

        if ($(articleACS).length > 0) {
            let accessories = new Accessories(articleACS);

            accessories.ini();
        }
        if ($(articleGLE).length > 0) {
            let glue = new Glue(articleGLE);

            glue.ini();
        }
        if ($(articleCUT).length > 0) {
            let cuts = new Cuts(articleCUT);

            cuts.ini();
        }
        if ($(articleRL).length > 0) {
            let rolls = new Rolls(articleRL);

            rolls.ini();
        }
        if ($(articleSML).length > 0) {
            let samples = new Samples(articleSML);

            samples.ini();
        }

        $('.busket-total__btn').click(function() {
            let categoryes = $('.busket-category');

            $(categoryes).each((index, item) => {
                makeOrder(item);
            });
        });
    }
});

class Basket {
    constructor(article) {
        this.article = $(article);
        this.bottom = $(this.article).next('.busket-category-result');
        this.total = {
            weight: $(this.bottom).find('.js-result--weight'),
            volume: $(this.bottom).find('.js-result--volume'),
            count: $(this.bottom).find('.js-result--count'),
        };

        let items = $(this.article).children('.order-busket-item');

        this.counters = [];

        $(items).each((index, item) => {
            this.counters.push({
                percentage: $(item).find('.order-busket-percent__item'),
                counter: $(item).find('.busket-shop-count--value'),
                id: $(item).attr('data-product'),
                value: null,
            });
        });

        this.canUpdate = true;
    }
    ini() {
        let that = this,
            counters = $(this.article).find('.busket-shop-count--value');

        this.formatCounter(counters);

        $(this.article)
            .find('.busket-delete-btn')
            .click(function(event) {
                that.getOrderData(event.target);
                that.deleteItem();
            });
        $(counters).on('keydown', function(event) {
            if (that.canUpdate) {
                that.currentValue = $(event.target).val();
                that.canUpdate = false;
            }
        });
        $(counters).on('input', function(event) {
            let input = $(event.target).val();

            if (isNaN(input)) {
                input.toString();
                input = input.slice(0, -1);
                $(event.target).val(input);
            }

            that.getOrderData(event.target);
            clearTimeout(that.timer);

            that.timer = setTimeout(function() {
                that.canUpdate = true;
                that.inputChangeValue();
            }, 1000);
        });
        $(this.article)
            .find('.busket-shop-count--minus')
            .click(function(event) {
                that.getOrderData(event.target);
                that.stepChangeValue(-1);
            });
        $(this.article)
            .find('.busket-shop-count--plus')
            .click(function(event) {
                that.getOrderData(event.target);
                that.stepChangeValue(+1);
            });
    }
    formatCounter(counters) {
        $(counters).each((index, item) => {
            let val = $(item).val();

            val = this.round(val);
            $(item).val(val);
        });
    }
    getOrderData(target) {
        this.parent = $(target).parents('.order-busket-item');
        this.counterBox = this.parent.find('.busket-shop-count--value');
        this.chars = {
            weight: $(this.parent).find('.js-basket-weight'),
            volume: $(this.parent).find('.js-basket-volume'),
        };
        this.units = {
            top: $(this.parent).find('.js-busket-units--top'),
            bot: $(this.parent).find('.js-busket-units--bot'),
        };
        this.defaultVal = parseFloat(+$(this.parent).attr('data-default'));
        this.increment = $(this.parent).attr('data-increment');
        this.increment =
            this.increment > 1 ? Math.round(this.increment) : this.increment;
        this.data = {
            product_id: $(this.parent).attr('data-product'),
            unit: $(this.parent).attr('data-unit'),
        };
    }
    stepChangeValue(e) {
        this.currentValue = this.data.count = $(this.counterBox).val();
        productOrder.stepChangeValue.call(this, e);
    }
    inputChangeValue() {
        productOrder.inputChangeValue.call(this);

        if (this.data.count > 0) {
            this.data.change = this.data.count - this.currentValue;

            this.changeSend();
        }
    }
    changeSend() {
        this.data.change = this.data.count - this.currentValue;
        $.ajax({
            url: '/api/baskets/products/count',
            type: 'PATCH',
            data: this.data,
            success: resp => {
                this.resp = resp;
                this.sendReaction();
            },
            error: () => {},
        });
    }

    round(input) {
        return productOrder.round(input);
    }

    deleteItem() {
        $.ajax({
            type: 'DELETE',
            url: '/api/baskets/products',
            data: this.data,
        }).then(resp => {
            let test = resp.hasOwnProperty('totalOptions')
                ? resp.totalOptions === undefined ||
                  parseFloat(resp.totalOptions.weightTotal) === 0 ||
                  resp.totalOptions.length === 0
                : true;

            if (test) {
                $(this.article).remove();
                $(this.bottom).remove();
            } else {
                this.counters = this.counters.filter(item => {
                    return item.id !== this.data.product_id;
                });

                this.resp = resp;

                $(this.parent).remove();

                this.updateTotal();
            }
        });
    }

    updateUnits() {
        let units = this.resp.options.units;

        this.units.top.text(units.top);
        this.units.bot.text(units.bot);
    }
    updateChars() {
        let chars = this.resp.options.total;

        this.chars.weight.text(chars.weight);
        this.chars.volume.text(chars.volume);
    }
    updateTotal() {
        let options = this.resp.totalOptions;

        this.total.weight.text(options.weightTotal);
        this.total.count.text(options.count);

        if (options.hasOwnProperty('volumeTotal')) {
            this.total.volume.text(options.volumeTotal);
        }
    }
    updateCount() {
        this.total.count.text(this.resp.totalOptions.count);
    }
    massRecount() {
        this.totalVal = this.counters.reduce(function(accumulator, item) {
            item.value = parseFloat($(item.counter).val());

            return accumulator + item.value;
        }, 0);
        this.counters.forEach(item => {
            item.value = (item.value / this.totalVal) * 100;
            item.value = Math.round(item.value * 100) / 100;
            $(item.percentage).text(item.value + '%');
        });
    }
}

class PPU extends Basket {
    getOrderData(target) {
        super.getOrderData(target);
    }
    sendReaction() {
        super.updateUnits();
        super.updateTotal();
        super.updateChars();
        super.massRecount();
    }
    async deleteItem() {
        await super.deleteItem();
        await super.massRecount();
    }
}

class Accessories extends Basket {
    getOrderData(target) {
        super.getOrderData(target);
    }
    sendReaction() {
        super.updateTotal();
        super.updateChars();
        super.updateUnits();
    }
}
class Glue extends Basket {
    getOrderData(target) {
        super.getOrderData(target);
    }
    sendReaction() {
        super.updateTotal();
        super.updateChars();
    }
}

class Cuts extends Basket {
    getOrderData(target) {
        super.getOrderData(target);
    }
    sendReaction() {
        super.updateTotal();
        super.updateCount();
        super.updateUnits();
    }
    async deleteItem() {
        await super.deleteItem();
    }
}

class Rolls extends Basket {
    getOrderData(target) {
        super.getOrderData(target);
    }
    sendReaction() {
        super.updateTotal();
        super.updateChars();
        super.updateUnits();
        super.updateCount();
    }
    async deleteItem() {
        await super.deleteItem();
    }
}
class Samples extends Basket {
    getOrderData(target) {
        super.getOrderData(target);
    }
    sendReaction() {
        super.updateUnits();
        super.updateTotal();
        super.updateCount();
    }
    async deleteItem() {
        await super.deleteItem();
    }
}

//
//
//
//
//
//

function openBusketDesc(e) {
    $(e).toggleClass('busket-aside__title--open');

    let slider = e.nextElementSibling;

    $(slider).slideToggle('fast');
}

function makeOrder(category) {
    let items = $(category).children('.order-busket-item'),
        baskets = [],
        delivery = null,
        data = {};

    $(items).each((index, item) => {
        baskets.push($(item).attr('data-basket'));
    });

    data.baskets = baskets;
    data.delivery_type = delivery;

    $.ajax({
        type: 'POST',
        url: '/api/baskets/orders',
        data: data,
        success: function(resp) {
            if (!basketClean) {
                $('.pop-up--basket').removeClass('pop-up--hidden');
                $('.busket-empty').removeClass('busket-hidden');
                $('.busket-main').remove();
                $('.busket-background').remove();
                $('.basket-sum').text('0 ₽');
                $('.basket-count').text('0 товаров');
                basketClean = true;
            }
        },
        error: function(resp) {},
    });
}
