import $ from 'jquery';
import 'cookie';

$(document).ready(() => {
    $('.blog-filter-tags .tag').on('click', function() {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
        } else {
            $(this).addClass('active');
        }
    });

    $('.mobile-menu-opener').on('click', function() {
        $('.page__inner').addClass('active-menu');

        let vh = window.innerHeight * 0.01,
            wh = window.innerHeight + 'px';

        $('body').css('height', wh);

        $('.page-header').css('--vh', `${vh}px`);
    });
    $(window).on('resize', () => {
        let vh = window.innerHeight * 0.01;

        $('.page-header').css('--vh', `${vh}px`);
        $('.stage-modal').css('--vh', `${vh}px`);
    });

    $('.close-mobile').on('click', function() {
        $(this)
            .closest('.page__inner')
            .removeClass('active-menu');

        $('body').css('height', 'auto');
    });

    // Меню

    if ($(window).width() > 1190) {
        $('.main-menu-el.with-submenu').hover(
            function() {
                $(this)
                    .find('ul')
                    .addClass('hover');
            },
            function() {
                $(this)
                    .find('ul')
                    .removeClass('hover');
            },
        );
    }

    $('.header-search-icon').on('click', function() {
        $('.icon-search-form').addClass('active');
    });

    $('.icon-search-form .cross').on('click', function() {
        $('.icon-search-form').removeClass('active');
    });
    $('.lang-control-select').on('change', function() {
        let lang = $(this).val();

        window.location.href = window.location.origin + '/locale/' + lang;
    });

    // Виджет перехода на старый сайт

    if (cookie.get('show_oldsite')) {
        $('.oldsite-widget.full').remove();
    }

    $('.oldsite-widget .close').click(function() {
        $(this)
            .parent()
            .remove();
    });
    cookie.set('show_oldsite', 'yes', 365);

    // interactive
    const stagesNames = window.ai18n.home.stagesNames,
        stages = window.ai18n.home.stages;

    let changeStageFlag = true;

    function changeStep(stepNumber) {
        if (changeStageFlag === false) {
            return;
        }

        changeStageFlag = false;

        $('.interactive-block')
            .removeClass(function(index, css) {
                return (css.match(/\bstage-place-\S+/g) || []).join(' ');
            })
            .addClass('stage-place-' + stepNumber)
            .data('stage-place', stepNumber);

        $('.stage-desc-name').html(stagesNames[stepNumber - 1]);
        changeStageFlag = true;
        /* setTimeout(function() {

        }, 2500); */
    }

    function moveRight() {
        if (changeStageFlag === false) {
            return;
        }

        let currentStage = $('.interactive-block').data('stage-place');

        if (currentStage === 8) {
            return;
        }

        currentStage++;
        changeStep(currentStage);
    }
    function moveLeft() {
        if (changeStageFlag === false) {
            return;
        }

        let currentStage = $('.interactive-block').data('stage-place');

        if (currentStage === 1) {
            return;
        }

        currentStage--;
        changeStep(currentStage);
    }

    $('.stage-block').on('click', function() {
        let stageNumber = $(this).data('stage');

        changeStep(stageNumber);
    });

    $('.interactive-block').on('mousewheel', function(e) {
        if (changeStageFlag === false) {
            e.preventDefault();

            return;
        }

        let event = window.event || e, // old IE support
            delta = Math.max(
                -1,
                Math.min(1, event.wheelDelta || -event.detail),
            ),
            currentStage = $('.interactive-block').data('stage-place');

        e.preventDefault();

        if (delta < 0) {
            if (currentStage === 8) {
                return;
            }

            currentStage++;
        } else {
            if (currentStage === 1) {
                return;
            }

            currentStage--;
        }

        changeStep(currentStage);
    });

    $('.interactive-block').swipe({
        swipeRight: function() {
            moveLeft();
        },
        swipeLeft: function() {
            moveRight();
        },
    });

    $('.modal-btn').on('click', function() {
        let stageNumber = $(this).data('modal');

        $('.stage-modal[data-stage="' + stageNumber + '"]').addClass(
            'active-stage-modal',
        );

        let vh = window.innerHeight * 0.01,
            wh = window.innerHeight + 'px';

        $('body').css('height', wh);

        $('.stage-modal').css('--vh', `${vh}px`);
    });

    $('.mouse-hint .left').on('click', function() {
        moveLeft();
    });

    $('.mouse-hint .right').on('click', function() {
        moveRight();
    });

    $('.stage-modal__cross, .stage-modal__background').on('click', function() {
        $(this)
            .closest('.stage-modal')
            .removeClass('active-stage-modal');

        $('body').css('height', 'auto');
    });

    for (let i = 1; i < 9; i++) {
        $('[data-fancybox="gallery-' + i + '"]').fancybox({
            buttons: ['close'],
            infobar: false,
            idleTime: false,
            loop: true,
            baseTpl:
                '<div class="fancybox-container" role="dialog" tabindex="-1">' +
                '<div class="fancybox-bg"></div>' +
                '<div class="fancybox-inner">' +
                '<div class="fancybox-stage"><div class="fancybox-toolbar">{{buttons}}</div><div class="fancybox-navigation">{{arrows}}</div></div>' +
                '<div class="fancybox-caption"></div>' +
                '</div>' +
                '</div>',
            btnTpl: {
                close:
                    '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">' +
                    '</button>',

                // Arrows
                arrowLeft:
                    '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">' +
                    '</button>',

                arrowRight:
                    '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">' +
                    '</button>',
            },
        });
    }

    $('.pagination .pagination__dot').on('click', function() {
        if (changeStageFlag === false) {
            return;
        }
        let number = $(this).index();

        changeStep(number);
    });

    /* $.ajax({
        type: 'GET',
        url: 'http://ip-api.com/json/?lang=ru',
        success: function(res) {
            $(".header-city-name").text(res.city);
        },
    }); */

    $('.main-slider').slick({
        // Настройки слайдера
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        dots: false,
        infinite: true,
    });

    let arrowContainerWidth =
            $(window).width() > 1320 ? $(window).width() - 1320 : 0,
        arrowOffset = arrowContainerWidth / 2 + 12,
        leftArrow = $('.banner-index-block .slick-prev'),
        rightArrow = $('.banner-index-block .slick-next');

    leftArrow.css('left', arrowOffset + 'px');
    rightArrow.css('right', arrowOffset + 'px');
});

if (window.ai18n === undefined) {
    window.ai18n = {};
}
