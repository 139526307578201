import $ from 'jquery';

$('#communicate-form__submit').click(function() {
    let response = grecaptcha.getResponse(); /* eslint-disable-line */

    if (response.length === 0) {
        return false;
    } else {
        return true;
        // отправка формы
    }
});
