import $ from 'jquery';

$(document).ready(function() {
    // Получаем элементы
    let $cityConfirmation = $('#cityConfirmation'),
        $citySelection = $('#citySelection'),
        $chooseCity = $('#choose-city'),
        $confirmYes = $('#confirmYes'),
        $confirmNo = $('#confirmNo'),
        $spanClose = $('.close').first();

    $cityConfirmation.show();

    $confirmYes.on('click', function() {
        let city = $('.header-city-name').text();

        sendCityData(city);
        $cityConfirmation.hide();
    });

    $confirmNo.on('click', function() {
        $cityConfirmation.hide();
        $citySelection.show();
    });

    $chooseCity.on('click', function() {
        $citySelection.show();
    });

    $spanClose.on('click', function() {
        $citySelection.hide();
    });

    $(window).on('click', function(event) {
        if ($(event.target).is($citySelection)) {
            $citySelection.hide();
        }
    });

    $('#cityList').on('click', 'li', function() {
        let selectedCity = $(this).text();

        sendCityData(selectedCity);
    });

    function sendCityData(selectedCity) {
        $.ajax({
            url: '/api/position',
            method: 'POST',
            data: {
                cityName: selectedCity,
                url: window.location.href,
            },
            xhrFields: {
                withCredentials: true, // Включить куки
            },
            timeout: 3000,
            success: function(response) {
                window.location.href = response.url;
            },
            error: function(xhr, status, error) {
                console.error('Error:', status, error);
                console.error('Response:', xhr.responseText);
            },
        });
    }
});
