import $ from 'jquery';
import { Paginator } from '../modules/pagination.js';

$(document).ready(() => {
    if ($('.search').length > 0) {
        let search = new SearchList();

        $('.search-row-show--products').click(function(event) {
            let row = $(event.target).parents('.search-row')[0],
                siblings = $(row).siblings('.search-row');

            search.wrap = $(row).find('.search-row-wrap--links');
            search.categories = $(row).attr('data-categories');
            search.lastPage = $(row).attr('data-last-page');
            search.getResults();

            if ($('.btn-more-results').length === 0) {
                $(row).after(
                    $(
                        '<div class="btn-wrap"><div class="btn btn-more-results">Показать ещё</div></div>',
                    ),
                );
            }
            if ($(siblings).length > 0) {
                $(siblings).remove();
                $('html, body').animate({ scrollTop: 0 }, 'slow');
            }

            $('.btn-more-results').click(function() {
                search.getResults();
            });
            $(event.target).remove();
        });
    }
});

class SearchList extends Paginator {
    lastPage = null;
    pageGlobal = 1;
    wrap = null;

    getResults() {
        let data = {},
            that = this;

        data.categories = JSON.parse(this.categories);
        data.page = this.pageGlobal;
        data.text = $('.search--title').attr('data-text');

        $.ajax({
            type: 'GET',
            url: '/api/search/other-products',
            data: data,
            success: function(resp) {
                if (+resp.lastPage === +that.pageGlobal) {
                    $('.btn-wrap').remove();
                }

                that.pageGlobal += 1;

                if (resp.discount !== null) {
                    that.createProductList(resp.data);
                }
            },
        });
    }

    createProductList(data) {
        super.createProductList.call(this, data, $(this.wrap), false);
    }
}
