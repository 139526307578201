import $ from 'jquery';

let lastPage,
    lastOpenedPage = 1,
    wrapper = $('.blog-index-wrapper'),
    search = $('.search--title').attr('data-text');

$(document).ready(() => {
    if ($(wrapper).length > 0) {
        $('.load-more-btn--posts').on('click', function() {
            getPosts(lastOpenedPage);
        });

        if (!search) {
            getPosts(lastOpenedPage);
        }
    }

    $('.search-row-show--posts').click(function(event) {
        let row = $(event.target).parents('.search-row')[0],
            siblings = $(row).siblings('.search-row');

        lastPage = $(row).attr('data-last-page');

        searchPosts(lastOpenedPage, search);

        if ($('.btn-more-results').length === 0) {
            $(row).after(
                $(
                    '<div class="btn-wrap"><div class="btn btn-more-results">Показать ещё</div></div>',
                ),
            );
        }

        $('.btn-more-results').click(function() {
            searchPosts(lastOpenedPage, search);
        });

        if ($(siblings).length > 0) {
            $(siblings).remove();
            $('html, body').animate({ scrollTop: 0 }, 'slow');
        }

        $(event.target).remove();
    });

    $('.pinned-slider').slick({
        // Настройки слайдера
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        dots: false,
        infinite: true,
    });
});

function searchPosts(pageNumber, search) {
    let blogUrl = '/api/posts/search',
        data = {};

    data.page = pageNumber;
    data.text = search;

    $.ajax({
        url: blogUrl,
        method: 'get',
        data: data,
        success: function(response) {
            response.data.forEach(item => {
                $(wrapper).append(addPost(item));
            });

            lastOpenedPage = pageNumber;

            if (+lastPage === lastOpenedPage) {
                $('.btn-more-results').remove();
            }

            lastOpenedPage++;
        },
    });
}

function getPosts(pageNumber) {
    let blogUrl = '/api/posts',
        data = {};

    data.page = pageNumber;

    $.ajax({
        url: blogUrl,
        method: 'get',
        data: data,
        success: function(response) {
            lastPage = response.lastPage;
            lastOpenedPage = pageNumber;

            if (lastPage === lastOpenedPage) {
                $('.load-more-btn--posts').css('display', 'none');
            }

            lastOpenedPage++;

            if (pageNumber !== 1) {
                response.data.forEach(item => {
                    $(wrapper).append(addPost(item));
                });
            }

            if ($('.photo-slider').length > 0) {
                response.data.forEach(item => {
                    $('.blog-index-wrapper').append(addPost(item));
                });
                $('.photo-slider').slick({
                    infinite: true,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    arrows: false,
                    variableWidth: false,
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                infinite: true,
                                slidesToShow: 3,
                                slidesToScroll: 3,
                                arrows: false,
                                ariableWidth: false,
                            },
                        },
                        {
                            breakpoint: 900,
                            settings: {
                                infinite: true,
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                arrows: false,
                                ariableWidth: false,
                            },
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                infinite: true,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                arrows: false,
                                ariableWidth: false,
                            },
                        },
                    ],
                });
            }
        },
    });
}

function addPost(resp) {
    let blogItem = document.createElement('div'),
        itemLink = document.createElement('a'),
        itemImg = document.createElement('img'),
        itemInner = document.createElement('div'),
        itemTitle = document.createElement('div'),
        itemTitleLink = document.createElement('a'),
        itemDate = document.createElement('div'),
        itemTags = document.createElement('div'),
        tags = addTags(resp.tags);

    blogItem.classList.add('blog-item');

    itemLink.classList.add('blog-item__link');
    itemLink.setAttribute('href', resp.link);
    blogItem.append(itemLink);

    itemImg.setAttribute('alt', resp.title);
    itemImg.setAttribute('src', resp.preview);
    blogItem.append(itemImg);

    itemInner.classList.add('blog-item__inner');

    itemDate.classList.add('blog-item__date');
    itemDate.innerHTML = resp.created_at;
    itemInner.append(itemDate);

    itemTags.classList.add('blog-item__tags');
    tags.forEach(item => {
        itemTags.append(item);
    });
    itemInner.append(itemTags);

    itemTitle.classList.add('blog-item__title');
    itemTitleLink.innerText = resp.title;
    itemTitleLink.classList.add('blog-item-title__link');
    itemTitleLink.setAttribute('href', resp.link);
    itemTitle.append(itemTitleLink);
    itemInner.append(itemTitle);

    blogItem.append(itemInner);

    return blogItem;
}

function addTags(tags) {
    let arr = [];

    if (tags !== undefined && tags.length > 0) {
        for (let i = 0; i < tags.length && i < 2; i++) {
            let tagItem = document.createElement('div');

            tagItem.classList.add('blog-item__tag');
            tagItem.innerHTML = tags[i].name;
            arr.push(tagItem);
        }

        return arr;
    }
}
