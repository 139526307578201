import $ from 'jquery';

$(document).ready(() => {
    $('.questions-item-front__icon').on('click', function(event) {
        openAnswer(event.target);
    });
});

function openAnswer(e) {
    let $parent = $(e).parent(),
        $slider = $parent.siblings('.questions-item-slider');

    $parent.toggleClass('questions-item--open');
    $slider.slideToggle('fast');
}
