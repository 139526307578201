import $ from 'jquery';

$(document).ready(() => {
    $('.history-year__button').on('click', function(event) {
        let yearId = parseInt(event.target.id.replace(/[^\d]/g, ''));

        nextYear(false, yearId);
        yearChosen(yearId);
    });
    $('.history-wrap-item__button').on('click', function() {
        nextYear(true);
        tapedButton += 1;

        if (tapedButton < years.length) {
            yearChosen(tapedButton);
        }
    });
});

let years = [1992, 2000, 2004, 2006, 2007, 2012, 2016, 2017, 2019, 2020, 2022],
    $lastChosen = $('.history-wrap-item__img--1992'),
    tapedButton = 0,
    title = window.ai18n.about_history.title,
    text = window.ai18n.about_history.text,
    shift = '';

function nextYear(justNext, year) {
    if (justNext || tapedButton < year) {
        shift = '+=200';
    } else {
        shift = '-=200';
    }

    $('.history-year').animate({ scrollLeft: shift }, 500);
}

function yearChosen(year) {
    let $changedYear = $('.history-wrap-item__img--' + years[year]),
        activateButton = '#' + 'history-' + year;

    tapedButton = year;
    $lastChosen.css('display', 'none');
    $lastChosen = $changedYear;
    $changedYear.css('display', 'block');

    $('.history-wrap__text').text(text[year]);

    $('.history-wrap__text').attr('data-before', title[year]);

    $('.history-year__button').removeClass('history-year__button_on');

    $(activateButton).addClass('history-year__button_on');
}
