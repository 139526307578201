import $ from 'jquery';
import { Paginator } from './pagination';
import 'datatables';

let $ratingTabs = $('.rating-type-switcher .tab'),
    $offsetTabs = $('.offset-type-switcher .tab'),
    ratingType = $ratingTabs.filter('.active').data('type'),
    offsetType = $offsetTabs.filter('.active').data('type'), // FIXME: rewrite filters
    t = $('#data-tables'),
    isManager,
    column,
    category = '',
    status = '',
    search = '',
    start = 0,
    emptyTable =
        window.location.pathname.split('/')[1] === 'ru'
            ? 'Нет данных в таблице'
            : 'No data available in table datatable',
    firstSort = true;

$(document).ready(() => {
    if ($('.order-table').length > 0) {
        isManager = $(t).attr('data-role');

        column = +isManager
            ? [
                  {
                      data: 'id',
                      name: 'id',
                      title: '',
                      defaultContent: '',
                  },
                  {
                      data: 'status',
                      name: 'status',
                      title: '',
                      defaultContent: '',
                  },
                  {
                      data: 'category_id',
                      name: 'category_id',
                      title: '',
                      defaultContent: '',
                  },
                  {
                      data: 'updated_at',
                      name: 'updated_at',
                      title: '',
                      defaultContent: '',
                  },
                  {
                      data: 'created_at',
                      name: 'created_at',
                      title: '',
                      defaultContent: '',
                  },
                  {
                      data: 'email',
                      name: 'email',
                      title: '',
                      defaultContent: '',
                  },
              ]
            : [
                  {
                      searchable: false,
                      orderable: false,
                      data: 'index',
                      name: 'index',
                      title: '',
                      defaultContent: '',
                  },
                  {
                      data: 'id',
                      name: 'id',
                      title: '',
                      defaultContent: '',
                  },
                  {
                      data: 'status',
                      name: 'status',
                      title: '',
                      defaultContent: '',
                  },
                  {
                      data: 'category_id',
                      name: 'category_id',
                      title: '',
                      defaultContent: '',
                  },
                  {
                      data: 'updated_at',
                      name: 'updated_at',
                      title: '',
                      defaultContent: '',
                  },
                  {
                      data: 'created_at',
                      name: 'created_at',
                      title: '',
                      defaultContent: '',
                  },
              ];

        t.DataTable({
            sort: true,
            searching: true,
            ordering: true,
            bJQueryUI: true,
            bRetrieve: true,
            bSortMulti: true,
            deferRender: true,
            bSort: true,
            processing: true,
            serverSide: true,
            stateSave: true,
            search: {
                smart: true,
            },
            language: {
                emptyTable: emptyTable,
            },
            ajax: {
                url: '/api/orders/processing',
                data: function(data) {
                    data.ratingType = ratingType;
                    data.offsetType = offsetType;
                    data.category = category;
                    data.status = status;
                    data.is_manager = isManager;
                    data.start = start;
                    data.order = [{ column: 4, dir: 'desc' }];
                },
            },
            columns: column,
            initComplete: function(settings, json) {
                addLinkToOrder(json);

                let searching = $('#data-tables_filter').find(
                    'label > input',
                )[0];

                $(searching).on('input', function() {
                    t.DataTable()
                        .columns()
                        .search($(this).val())
                        .draw();
                });
                $(searching).attr('placeholder', 'Быстрый поиск');
            },
        });

        if (isManager) {
            $('#sorter-0')
                .children('.order-table--icon')
                .css('display', 'inline');
            $('#sorter-0').click(function() {
                sortColumn(0);
            });
        }

        $('#sorter-1').click(function() {
            sortColumn(1);
        });
        $('#sorter-2').click(function() {
            sortColumn(2);
        });
        $('#sorter-3').click(function() {
            sortColumn(3);
        });
        $('#sorter-4').click(function() {
            sortColumn(4);
        });
        $('#sorter-5').click(function() {
            sortColumn(5);
        });
        $('.order-table-console__item').click(function(event) {
            filterByStatus($(event.target).attr('data-status'));
            $('.order-table-console__item').removeClass(
                'order-table-console__item--active',
            );
            $(event.target).toggleClass('order-table-console__item--active');
        });

        if ($('#data-tables').length > 0) {
            $('.aside-menu-slider__subcategory').click(function(event) {
                filterByCategory($(event.target).text());
            });
            $('.aside-menu-filter').toggleClass('aside-menu--hide');
            $('.aside-menu-slider--out ').toggleClass(
                'aside-menu-slider--open',
            );
        }
    }
});

let sortHistory = {
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
};

function sortColumn(e) {
    if (sortHistory.e) {
        sortHistory.e = false;
        t.DataTable()
            .order([e, 'asc'])
            .draw();
    } else {
        sortHistory.e = true;
        t.DataTable()
            .order([e, 'desc'])
            .draw();
    }

    t.DataTable().ajax.reload(json => {
        addLinkToOrder(json);
    });
}

function filterByCategory(newCategory) {
    let variable = $('.console-variable');

    if (newCategory === 'Пенополиуретан') {
        $(variable).text('В производство');
    } else {
        $(variable).text('На сборку ');
    }

    start = 0;
    tablePaginator.chosenPage = 1;
    tablePaginator.startPage = 1;
    tablePaginator.productGrid.page = 1;
    category = newCategory;
    t.DataTable().ajax.reload(json => {
        addLinkToOrder(json);
    });
}
function filterByStatus(newStatus) {
    status = newStatus;
    start = 0;
    tablePaginator.chosenPage = 1;
    tablePaginator.startPage = 1;
    tablePaginator.productGrid.page = 1;
    t.DataTable().ajax.reload(json => {
        addLinkToOrder(json);
    });
}

let list = $('.pagination-wrap');

function addLinkToOrder(resp) {
    let table = $('tbody'),
        rows = table[0].children,
        all = resp.recordsTotal;

    $(list).empty();

    tablePaginator.lastPage = Math.ceil(all / 10);

    for (let i = 0; i < rows.length; i++) {
        $(rows[i]).click(function() {
            window.location.href = window.location.href + '/' + resp.data[i].id;
        });
    }

    tablePaginator.list = list;
    tablePaginator.createPaginationList();
}

let tablePaginator = new Paginator();

tablePaginator.createList = function() {
    start = (tablePaginator.chosenPage - 1) * 10;
    t.DataTable().ajax.reload(json => {
        addLinkToOrder(json);
        tablePaginator.createPaginationList();
    });
};
