import $ from 'jquery';
import { Paginator } from '../modules/pagination.js';

$(document).ready(() => {
    if ($('.category').length > 0) {
        let categoryList = new Category();

        categoryList.lastPage = $('.js-category-last-page').data('pages');
        categoryList.createPaginationList();

        $('.subcategory-list').length > 0
            ? (categoryList.address = '/api/categories/children')
            : (categoryList.address = '/api/products');

        let categoryFilter = {
            slider: '.category-filter-slider ',
            arrow: '.category-filter__icon',
            opened: {
                depth: false,
                width: false,
                length: false,
            },
            toggle: function(id) {
                let target = '#' + id,
                    parent = $(target),
                    slider = $(parent).find(categoryFilter.slider),
                    filterArrow = $(parent).find(categoryFilter.arrow);

                $(slider).toggleClass('category-filter-slider--open');
                $(filterArrow).toggleClass('category-filter__icon--open');

                this.opened[id] = !this.opened[id];
            },
        };

        $('.js-category-filter').click(function(e) {
            let target = $(e.target).closest('.js-category-filter'),
                id = $(target).attr('id');

            for (let item in categoryFilter.opened) {
                if (categoryFilter.opened[item] || item === id) {
                    categoryFilter.toggle(item);
                }
            }
        });

        $('.js-category-filter__item').click(function(e) {
            categoryList.startFilter(e);
        });
    }
});

class Category extends Paginator {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super();
    }

    filterData = {
        depth: null,
        width: null,
        length: null,
    };
    address = '';
    lang = $('html').attr('lang');

    startFilter(e) {
        let page = 1,
            category = $('.category-products').attr('data-category'),
            filterColumn = $(e.target)
                .parents('.category-filter-front')
                .attr('id'),
            filterValue = $(e.target).text(),
            $title = $(e.target)
                .parents('.js-category-filter')
                .find('.js-category__title');

        $title.text(filterValue);

        filterValue = parseFloat(filterValue);

        filterValue = isNaN(filterValue) ? null : filterValue;

        this.productGrid.category_id = this.filterData.category_id = +category;
        this.productGrid.page = this.chosenPage = +page;
        this.productGrid.text = $('.search--title').attr('data-text');
        this.productGrid.product1c_filters = this.filtersQuery();
        this.pages = [];

        this.createList();
        this.getFilters();

        let langTable = {
            ru: {
                length: 'Длина',
                depth: 'Толщина',
                width: 'Ширина',
            },
            en: {
                length: 'Length',
                depth: 'Depth',
                width: 'Width',
            },
        };

        if (filterValue === null) {
            filterValue = langTable[this.lang][filterColumn];

            $title.text(filterValue);
        }
    }

    getFilters() {
        let that = this;

        $.ajax({
            type: 'GET',
            url: '/api/products/filters',
            data: this.filterData,
            success: function(resp) {
                that.createFilterList(resp.data);
            },
        });
    }

    filtersQuery() {
        let query = [];

        $('.js-category-filter').each(function() {
            let column = $(this).attr('id'),
                value = $(this)
                    .find('.category-filter-front__title')
                    .text();

            if (isNaN(parseInt(value))) {
                return;
            }

            query.push({ column, value });
        });

        return query;
    }

    createFilterList(resp) {
        for (let prop in resp) {
            let filterItem = document.createElement('span'),
                slider = $('#' + prop).find('.category-filter-slider'),
                elems = resp[prop];

            $(slider).empty();
            $(slider).append(filterItem);
            $(filterItem).addClass('category-filter-slider__item');
            $(filterItem).text('Не выбрано');
            elems.forEach((item, index) => {
                let filterItem = document.createElement('span');

                $(filterItem).addClass('category-filter-slider__item');
                $(slider).append(filterItem);
                $(filterItem).text(item);
            });
        }
        let that = this;

        $('.category-filter-slider__item').click(function(e) {
            that.startFilter(e);
        });
    }

    createList() {
        let that = this;

        $.ajax({
            type: 'GET',
            url: this.address,
            data: this.productGrid,
            success: function(response) {
                that.lastPage = response.lastPage;

                that.createProductList(response.data);
                that.createPaginationList();
            },
        });
    }
    createProductList(data) {
        super.createProductList.call(this, data, $('.category-products'));
    }

    createPaginationList() {
        super.createPaginationList.call(this);
    }
}
