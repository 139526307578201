import $ from 'jquery';
import 'jquery-slimscroll/jquery.slimscroll.min.js';

let markers = [],
    places,
    label;

$(document).ready(() => {
    if ($('.map-aside').length > 0) {
        $('.map-aside-cities').click(() => {
            $('.pop-up__map-city').toggleClass('pop-up--hidden');
            $('body').toggleClass('pop-up--overflow');
        });
        $('.map-city-input').keyup(function(event) {
            citySearch($(event.target).val());
        });
    }
});

async function getPlaces() {
    await $.ajax({
        type: 'GET',
        url: '/api/partners',
        success: function(response) {
            places = response.places;
            label = response.label;

            for (let i = 0; i < places.length; i++) {
                createCities(places[i]);
            }
        },
    });
}

async function getGeoPosition() {
    let response = null;

    await $.ajax({
        type: 'GET',
        url: '/api/contacts/geo-position',
        success: function(res) {
            response = JSON.parse(res);
        },
    });

    return response;
}

let customMarker = '/assets/images/map-mark.png';

function createCities(place) {
    let marker = new ymaps.Placemark(
        [place.geometry.location.lat, place.geometry.location.lng],
        {
            balloonContent:
                '<div><strong>' +
                place.name +
                '</strong><br>' +
                place.address +
                '</div>',
        },
        {
            iconLayout: 'default#image',
            iconImageHref: customMarker,
            iconImageSize: [150, 55],
            iconImageOffset: [-70, -55],
        },
    );

    markers.push(marker);
}

function createAside(place, i, map) {
    i += 1;

    return $('<div>')
        .on('click', function() {
            map.setCenter([
                place.geometry.location.lat,
                place.geometry.location.lng,
            ]);
        })
        .addClass('map-aside-wrap')
        .append(
            $('<div>')
                .addClass('flex flex-start')
                .append(
                    $('<div>')
                        .addClass('map-aside-wrap__number')
                        .text(i),
                )
                .append(
                    $('<div>')
                        .addClass('map-aside-wrap__title')
                        .text(place.name),
                ),
        )
        .append(
            $('<span>')
                .addClass('map-aside-wrap__item map-aside-wrap__addr')
                .text(place.address),
        )
        .append(
            $('<span>')
                .addClass('map-aside-wrap__item map-aside-wrap__phone')
                .text(place.phone),
        )
        .append(
            $('<span>')
                .addClass('map-aside-wrap__item map-aside-wrap__mail')
                .text(place.mail),
        )
        .append(
            $('<span>')
                .addClass('map-aside-wrap__item map-aside-wrap__time')
                .text(place.time),
        );
}

function addLinksToCities(map) {
    let links = $('.map-city-list').find('.map-city-column__item');

    for (let j = 0; j < links.length; j++) {
        let currentCity = places.find(item => {
            if (item.city === links[j].dataset.city) {
                return item;
            }
        });

        $(links[j]).click(() => {
            map.setCenter([
                currentCity.geometry.location.lat,
                currentCity.geometry.location.lng,
            ]);
            filterLinks(map, links[j].dataset.city);

            $('.pop-up__map-city').toggleClass('pop-up--hidden');
            $('body').toggleClass('pop-up--overflow');
        });
    }
}

function filterLinks(map, key, type = 'all') {
    let box = $('.map-aside-box');

    box.empty();
    $('.title', box).remove();
    // setMapOnAll(null);
    // markers = [];

    $('.map-aside__place').text(label + ' ' + key);
    $('.map-city-title__second').text(' ' + key);

    let filteredOffice = [],
        filteredFactory = [];

    switch (type) {
        case 'all':
            filteredOffice = places.filter(
                item => item.city === key && item.factory === 0,
            );
            filteredFactory = places.filter(
                item => item.city === key && item.factory === 1,
            );
            break;
        case 'office':
            filteredOffice = places.filter(
                item => item.city === key && item.factory === 0,
            );
            break;
        case 'factory':
            filteredFactory = places.filter(
                item => item.city === key && item.factory === 1,
            );
            break;
    }

    if (filteredOffice.length > 0) {
        box.append('<div class="title">Офисы</div>');

        for (let i = 0; i < filteredOffice.length; i++) {
            let item = createAside(filteredOffice[i], i, map),
                wrap = $('.map-aside-box');

            wrap.append(item);
            // createCities(filteredOffice[i], map, i);
        }
    }

    if (filteredFactory.length > 0) {
        box.append('<div class="title">Производство</div>');

        for (let i = 0; i < filteredFactory.length; i++) {
            let item = createAside(filteredFactory[i], i, map),
                wrap = $('.map-aside-box');

            wrap.append(item);
            // createCities(filteredFactory[i], map, i);
        }
    }
}

function citySearch(key) {
    if (key.length > 0) {
        $('.map-city-column').addClass('map-city--hidden');
        $('.map-city-search').removeClass('map-city--hidden');

        let target = $('.map-city-search').children();

        $(target).each((index, item) => {
            let align = true;

            for (let j = 0; j < key.length; j++) {
                align *=
                    $(item)
                        .text()
                        .toLowerCase()[j] === key[j].toLowerCase();
            }
            if (align) {
                $(item).removeClass('map-city--hidden');
            } else {
                $(item).addClass('map-city--hidden');
            }
        });
    } else {
        $('.map-city-search').addClass('map-city--hidden');

        $('.map-city-column').removeClass('map-city--hidden');
    }
}

function locateNearest(pos) {
    let nearest = 10000000,
        city;

    for (let i = 0; i < places.length; i++) {
        let place = places[i].geometry.location,
            z = Math.pow(
                Math.pow(pos.lat - place.lat, 2) +
                    Math.pow(pos.lng - place.lng, 2),
                0.5,
            );

        if (nearest > z) {
            nearest = z;
            city = i;
        }
    }

    return city;
}

function getCityPlaceID(city) {
    for (let i = 0; i < places.length; i++) {
        if (places[i].city.toLowerCase() === city.toLowerCase()) {
            return i;
        }
    }

    return false;
}

$(function() {
    $('.map-aside-box').slimScroll({
        height: '376px',
        railVisible: true,
        alwaysVisible: true,
    });
});

async function initMap() {
    let pos = {},
        city,
        country;

    await getPlaces();

    let cityResponse = await getGeoPosition();

    if (cityResponse.city) {
        city = getCityPlaceID(cityResponse.city);
    }

    if (city) {
        mapStart(city);
    } else if (cityResponse.status != 'fail') {
        pos = {
            lat: cityResponse.lat,
            lng: cityResponse.lng,
        };
        city = locateNearest(pos);
        country = cityResponse.country;

        if (country == 'Узбекистан')
            mapStart(getCityPlaceID('Ахангаран'), 'factory');
        else mapStart(city);
    }
    if (!city) {
        pos = {
            lat: 55.81125,
            lng: 49.072147,
        };
        city = locateNearest(pos);
        mapStart(city);
    }
}

function mapStart(city, type = 'all') {
    let myMap = new ymaps.Map('map', {
        center: [
            places[city].geometry.location.lat,
            places[city].geometry.location.lng,
        ],
        zoom: 12,
        controls: [],
    });

    myMap.controls.add('zoomControl');
    myMap.controls.add('fullscreenControl');
    setMapOnAll(myMap);
    filterLinks(myMap, places[city].city, type);
    addLinksToCities(myMap);
}

function setMapOnAll(myMap) {
    for (let i = 0; i < markers.length; i++) {
        myMap.geoObjects.add(markers[i]);
    }
}

if (typeof ymaps !== 'undefined') {
    ymaps.ready(initMap);
}
