import $ from 'jquery';

$(document).ready(() => {
    $('.aside-menu__title').on('click', function() {
        toggleAside();
    });
    $('.aside-menu-item--out').on('click', function() {
        toggleOrder(0);
    });
    $('.aside-menu-item--in').on('click', function() {
        toggleOrder(1);
    });
    $('.category-list-item--polymer').on('click', function() {
        toggleOrder(2);
    });
    $('.category-list-item--textile').on('click', function() {
        toggleOrder(3);
    });
    $('.category-list-item--accessories').on('click', function() {
        toggleOrder(4);
    });
    $('.category-list-item--glow').on('click', function() {
        toggleOrder(5);
        $('.category-list-item--glow').toggleClass('category-list-item--open');
    });
});

let sliders = [
        '.aside-menu-slider--out',
        '.aside-menu-slider--in',
        '.category-list-slider--polymer',
        '.category-list-slider--textile',
        '.category-list-slider--accessories',
        '.category-list-slider--glow',
    ],
    icons = [
        '.aside-menu-icon--out',
        '.aside-menu-icon--in',
        '.aside-menu-icon--polymer',
        '.aside-menu-icon--textile',
        '.aside-menu-icon--accessories',
        '.aside-menu-icon--glow',
    ];

function toggleOrder(e) {
    $(sliders[e]).toggleClass('aside-menu-slider--open');
    $(icons[e]).toggleClass('aside-menu-icon--open');
}

function toggleAside() {
    $('.aside-menu-item').toggleClass('aside-menu-item--open');
    $('.aside-menu-icon--title').toggleClass('aside-menu-icon--open');

    sliders.forEach(item => {
        $(item).removeClass('aside-menu-slider--open');
    });
    icons.forEach(item => {
        $(item).removeClass('aside-menu-icon--open');
    });
}
